import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [4,5];

export const dictionary = {
		"/": [6],
		"/blog": [~7,[2]],
		"/blog/[slug]": [~8,[2]],
		"/dashboard": [9,[3]],
		"/dashboard/list": [10,[3]],
		"/dashboard/premium": [11,[3]],
		"/dashboard/server-config": [12,[3]],
		"/dashboard/user": [13,[3]],
		"/dashboard/utilities": [14,[3]],
		"/dashboard/utilities/avatar-downloader": [15,[3]],
		"/dashboard/utilities/import-export": [16,[3]],
		"/guide": [17,[4]],
		"/guide/commands": [~19,[4]],
		"/guide/commands/[slug]": [~20,[4]],
		"/guide/[slug]": [~18,[4]],
		"/policy": [21,[5]],
		"/policy/[slug]": [~22,[5]],
		"/premium": [23],
		"/survey": [24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';